<template>
  <v-container d-flex align-start class="login-page pt-10">
    <LoginForm />
  </v-container>
</template>

<script>
import LoginForm from "@/components/LoginForm";

export default {
  name: "Login",
  components: {
    LoginForm
  },
  created() {
    if (localStorage.getItem('app_dp_drw')) {
      this.$router.push('/dashboard');
    }
  }
}
</script>

<style lang="scss" scoped>
  .login-page {
    height: 100%;
  }
</style>
