<template>
  <v-container class="dashboard-page">
    <div v-if="loading" class="loading-data">
      <v-skeleton-loader
        class="mx-auto mb-5"
        max-width="300"
        type="article, actions"
        elevation="2"
      ></v-skeleton-loader>
      <v-skeleton-loader
        class="mx-auto"
        max-width="300"
        type="article, actions"
        elevation="2"
      ></v-skeleton-loader>
    </div>
    <div v-else>
      <div v-if="orders.data.length">
        <div class="block-title">
          Заказы на доставку
        </div>
        <DeliveryCard
          v-for="(order, idx) in ordersOnDelivery"
          :order="order"
          class="mx-auto mb-5"
          :key="`do-${idx}`"
          @on-change-status="changeStatus"
        />

        <div class="all-done" v-if="ordersOnDelivery.length === 0">
          <Logo color="#aaaaaa"/>
          <span>Все заказы выполнены</span>
        </div>

        <div class="block-title">
          Доставленные заказы
        </div>

        <DeliveryCard
          v-for="(order, idx) in ordersDelivered"
          :order="order"
          class="mx-auto mb-5"
          :key="`d-${idx}`"
          hide-actions
        />

        <div class="all-done" v-if="ordersDelivered.length === 0">
          <Logo color="#aaaaaa"/>
          <span>Еще ничего не доставлено</span>
        </div>
      </div>
      <div v-else class="no-orders">
        Все доставлено! Ждем новые заказы
      </div>
    </div>
    <modal-dialog ref="statusModal" />
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import ModalDialog from "@/components/ModalDialog";
import Logo from '@/components/Logo';
import DeliveryCard from '@/components/DeliveryCard';

export default {
  name: "Dashboard",
  components: {
    ModalDialog,
    Logo,
    DeliveryCard,
  },
  data: () => ({
  }),
  async created() {
    await this.$store.dispatch('getDeliveryData');
    await this.$store.dispatch('getStatuses');
  },
  computed: {
    ...mapState({
      loading: state => state.loading,
      orders: state => state.orders,
    }),
    ordersOnDelivery() {
      return this.orders.data.filter(el => el.state === 'confirm' || el.state === 'progress');
    },
    ordersDelivered() {
      return this.orders.data.filter(el => el.state === 'cancel' || el.state === 'done' || el.state === 'failure');
    }
  },
  methods: {
    changeStatus(order) {
      this.$refs.statusModal.openModal(order);
    }
  },
}
</script>

<style lang="scss" scoped>
  .dashboard-page {
    height: 100%;
  }
  .block-title {
    font-size: 24px;
    text-align: center;
    padding: 16px;
  }
  .all-done {
    margin-bottom: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      color: #333333;
    }
  }
  //.number {
  //  color: #263238;
  //}
  //.v-card__text {
  //  overflow: hidden;
  //}
  //.no-orders {
  //  padding-top: 90px;
  //  display: flex;
  //  width: 100%;
  //  height: 100%;
  //  justify-content: center;
  //  align-items: center;
  //}
  //.order-so {
  //  margin-top: -16px;
  //  margin-left: -16px;
  //  margin-bottom: 24px;
  //  width: calc(100% + 32px);
  //  padding: 15px 20px;
  //  text-align: center;
  //  font-size: 24px;
  //  background-color: #0277BD;
  //  color: #ffffff;
  //  &.internal {
  //    background-color: #00838F;
  //  }
  //}
  //.delivered-order {
  //  .order-so {
  //    background-color: #aaaaaa;
  //  }
  //  .title {
  //    color: #333333 !important;
  //  }
  //}
</style>
