<template>
  <v-card
    class="pt-10 pb-5 pr-5 pl-5 mx-auto"
    width="100%"
    max-width="360"
  >
    <v-form class="mb-5">
      <v-text-field
        v-model="login"
        label="Login"
        class="mb-5"
        required
      />
      <v-text-field
        v-model="password"
        label="Password"
        type="password"
        class="mb-5"
        required
      />
      <div class="d-flex justify-center">
        <v-btn
          @click="loginUser"
          color="primary"
          elevation="2"
          rounded
          large
          :loading="loading"
          :disabled="!canSubmit || loading"
        >
          Login
        </v-btn>
      </div>
    </v-form>
    <v-alert
      v-if="errorMessage.length"
      icon="mdi-shield-lock-outline"
      prominent
      text
      type="info"
    >
      {{ errorMessage }}
    </v-alert>
  </v-card>
</template>

<script>
import apiClient from "@/api/apiClient";

export default {
  name: "LoginForm",
  data: () => ({
    login: '',
    password: '',
    errorMessage: '',
    loading: false,
  }),
  computed: {
    canSubmit() {
      return Boolean(this.login.length && this.password.length);
    }
  },
  methods: {
    async loginUser() {
      this.loading = true;
      this.errorMessage = '';
      try {
        const params = {
          db: 'lorashen_group',
          login: this.login,
          password: this.password,
          lang: 'uk_UA'
        };
        const { data } = await apiClient.get(
          '/auth/token',
          { params: params }
          );
        if (data.result === "OK") {
          localStorage.setItem('app_dp_drw', data.access_token);
          this.$store.dispatch('setLoggedIn', true);
          await this.$router.push('/dashboard');
        }
      } catch (err) {
        this.loading = false;
        this.errorMessage = err.response.data.message;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep .v-btn {
    width: 100%;
  }
</style>
