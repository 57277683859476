<template>
  <v-card
    class="mx-auto mb-5"
    :class="{ 'delivered-order': order.stage_id[0] === 5 || order.stage_id[0] === 8 || order.stage_id[0] === 9 }"
  >
    <!--
    :class="{ 'delivered-order': order.stage_id[0] === 2 || order.stage_id[0] === 7 || order.stage_id[0] === 38 || order.stage_id[0] === 44 }"
    -->
    <v-card-text>
      <div
        class="order-so"
        :class="{ 'internal': !order.order_id }"
      >
        {{ order.order_id ? order.order_id[1] : 'Внутренняя поездка' }}
      </div>
      <v-chip
        class="mb-2 mr-2"
        color="success"
        text-color="white"
      >
        {{ order.stage_id[1] }}
      </v-chip>
      <v-chip class="mb-2">
        {{ order.delivery_time_range2 }}
      </v-chip>
      <v-chip
        class="ml-2 mb-2"
        v-if="order.kws_lorashen_site_id"
      >
        {{ order.kws_lorashen_site_id[1] }}
      </v-chip>
      <p class="font-weight-normal text--primary pt-2 pb-2 mb-0 title">
        {{ order.destination_address }}
      </p>
      <v-divider></v-divider>
      <div class="headline pt-2 pb-2">
        {{ order.receiver_name }}
        <br>
        <a
          class="text-decoration-none headline number"
          :href="`tel:${order.receiver_phone}`"
        >
          {{ order.receiver_phone }}
        </a>
      </div>
      <v-divider></v-divider>
      <div class="subheading pt-2">
        {{ !order.order_id ? order.comment : order.order_comment }}
      </div>
      <!--
      <div v-if="order.order_id">
        <v-divider></v-divider>
        <div class="payment-info">
          Статус оплаты: <span>{{ order.kws_lorashen_billing_detail_payment_type }}</span>
        </div>
      </div>
      -->
      <div
        v-if="order.order_additional_instructions"
        class="additional-instruction"
      >
        <div class="additional-instruction__title">
          Дополнительные инструкции:
        </div>
        <div class="additional-instruction__content">
          {{ order.order_additional_instructions }}
        </div>
      </div>
      <div
        v-if="order.comment"
        class="additional-instruction"
      >
        <div class="additional-instruction__title">
          Комментарий логиста:
        </div>
        <div class="additional-instruction__content">
          {{ order.comment }}
        </div>
      </div>
      <div
        v-if="order.order_partner_id && order.order_partner_phone"
        class="client-origin"
      >
        <div class="client-origin__title">
          Контакты заказчика:
        </div>
        <div class="client-origin__name">
          {{ order.order_partner_id[1] }}
        </div>
        <a :href="`tel:${order.order_partner_phone}`" class="client-origin__phone">
          {{ order.order_partner_phone }}
        </a>
      </div>
    </v-card-text>
    <v-card-actions v-if="!hideActions">
      <v-btn
        @click="$emit('on-change-status', order)"
        class="ml-auto"
        color="success"
        large
      >
        Изменить статус
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'DeliveryCard',
  props: {
    order: {
      type: Object,
      required: true,
    },
    hideActions: {
      type: Boolean,
      default: false,
    }
  },
};
</script>

<style lang="scss" scoped>
.number {
  color: #263238;
}
.v-card__text {
  overflow: hidden;
}
.no-orders {
  padding-top: 90px;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.order-so {
  margin-top: -16px;
  margin-left: -16px;
  margin-bottom: 24px;
  width: calc(100% + 32px);
  padding: 15px 20px;
  text-align: center;
  font-size: 24px;
  background-color: #0277BD;
  color: #ffffff;
  &.internal {
    background-color: #00838F;
  }
}
.delivered-order {
  .order-so {
    background-color: #aaaaaa;
  }
  .title {
    color: #333333 !important;
  }
}
.additional-instruction {
  border: 1px solid #dedede;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  &__title {
    font-weight: 600;
    margin-bottom: 5px;
  }
  &__content {
    line-height: 1.25;
  }
}
.client-origin {
  border: 1px solid #dedede;
  border-radius: 10px;
  padding: 10px;
  &__title {
    font-weight: 600;
    margin-bottom: 5px;
  }
  &__name {
    font-size: 18px;
    line-height: 1.25;
    margin-bottom: 10px;
  }
  &__phone {
    font-size: 18px;
    text-decoration: none;
    color: #0277BD;
  }
}
</style>
