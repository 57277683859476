import Vue from 'vue'
import apiClient from '@/api/apiClient';
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

Vue.config.productionTip = false

Vue.prototype.$http = apiClient;
apiClient.defaults.timeout = 10000;
/* eslint-disable no-param-reassign */
apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('app_dp_drw');
    if (token) {
      config.headers.common.authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

Sentry.init({
  Vue,
  dsn: "https://5acdf95fa4cd4d8681c804f20dd550f7@o370525.ingest.sentry.io/5566736",
  autoSessionTracking: true,
  integrations: [
    new Integrations.BrowserTracing(),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  logErrors: true,
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
