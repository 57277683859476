import Vue from 'vue'
import Vuex from 'vuex'
import apiClient from "@/api/apiClient";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loggedIn: false,
    statuses: [],
    orders: [],
    loading: false,
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.loggedIn = value;
    },
    SET_STATUSES(state, value) {
      state.statuses = value;
    },
    SET_ORDERS(state, value) {
      state.orders = value;
    }
  },
  actions: {
    setLoggedIn({ commit }, value) {
      commit('SET_LOGGED_IN', value);
    },
    async getStatuses({ commit }) {
      try {
        const response = await apiClient.get("/kws.errand.stage/?fields=['id','name','state']");
        if (response.status === 200) {
          commit('SET_STATUSES', response.data.data)
        }
      } catch (e) {
        //
      }
    },
    async getDeliveryData({ commit, state }) {
      state.loading = true;
      const today = new Date();
      const formattedToday = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
      const apiUrl = `/kws.errand.errand/?fields=['id','kws_lorashen_site_id','name','state','stage_id','destination_address','duration_in_hours','start_date','start_time','end_time','comment','order_id','delivery_time_range','delivery_time_range2','order_comment','receiver_name','receiver_phone', 'kws_lorashen_billing_detail_payment_type','order_additional_instructions','order_partner_id','order_partner_phone']&domain=[('start_date','=','${formattedToday}')]&limit=1000`;
      try {
        const { data } = await apiClient.get(apiUrl);
        commit('SET_ORDERS', data);
        state.loading = false;
      } catch (e) {
        state.loading = false;
      }
    },
  },
  modules: {
  }
})
