import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/views/Login";
import Dashboard from "@/views/Dashboard";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Login,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { requiresLogin: true },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresLogin) && !localStorage.getItem('app_dp_drw')) {
    next('/');
  } else {
    next();
  }
});

export default router
