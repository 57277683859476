<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        persistent
        fullscreen
        max-width="300"
    >
      <v-card>
        <v-card-title class="headline text-center text-uppercase">
          Изменение статуса заказа
        </v-card-title>
        <div>
          <v-card-text class="flex-column">
            <v-radio-group v-model="picked">
              <v-radio
                v-for="(status, index) in driverStatuses"
                class="radio-card elevation-1"
                :key="index"
                :label="`${status.name}`"
                :value="status.id"
              />
            </v-radio-group>
          </v-card-text>
        </div>
        <v-card-actions>
          <v-btn
            @click="dialog = false"
            elevation="2"
            :disabled="updatingData"
            x-large
          >
            ОТМЕНИТЬ
          </v-btn>
          <v-btn
            @click="saveForm"
            elevation="2"
            color="error"
            :disabled="updatingData || picked === null"
            x-large
          >
            ИЗМЕНИТЬ
          </v-btn>
        </v-card-actions>
        <v-alert
          v-if="errors !== null"
          icon="mdi-alert-octagon"
          prominent
          text
          type="error"
        >
          {{ errors }}
        </v-alert>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {mapState} from "vuex";
import apiClient from '@/api/apiClient';

export default {
  name: "ModalDialog",
  data () {
    return {
      dialog: false,
      picked: null,
      orderId: null,
      updatingData: false,
      errors: null,
    }
  },
  computed: {
    ...mapState({
      statuses: state => state.statuses,
    }),
    driverStatuses() {
      return this.statuses.filter(el => el.state === 'done' || el.state === 'failure');
    }
  },
  methods: {
    openModal(params) {
      this.dialog = true;
      this.picked = null;
      this.orderId = params.id;
      this.updatingData = false;
      this.errors = null;
      // this.picked = params.stage_id[0];
    },
    async saveForm() {
      this.errors = null;
      this.updatingData = true;

      const fd = new FormData();
      fd.append("stage_id", this.picked);
      fd.append("id", this.orderId);

      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }

      try {
        const response = await apiClient.put('/kws.errand.errand', fd, { config });
        if(response.status === 200) {
          await this.$store.dispatch('getDeliveryData');
          this.updatingData = false;
          this.dialog = false;
        } else {
          console.log(response.result);
        }
      } catch (e) {
        const { error, message } = e.response.data;
        this.errors = `${error} - ${message}`;
      } finally {
        this.updatingData = false;
      }
    },
  }
}

</script>

<style lang="scss" scoped>
.v-card__title {
  word-break: normal;
}
.v-card__actions {
  justify-content: space-around;
}
.v-input--selection-controls {
  margin-top: 0;
}
.radio-card {
  padding: 20px 10px;
}
</style>
