<template>
  <v-app>
    <v-app-bar
      app
      dark
    >
      <div class="d-flex align-center logo-header">
        <svg viewBox="0 0 169.46 174.81">
          <g>
            <path
              d="M48.33 150.59s16.14 24.49 47.09 24.21c40.67 0 53.35-49.85 53.35-49.85s20.25-23 20.68-52.75-25.61-61.48-56.38-61.48c0 0-12.4-11-39.74-10.72C36.28 0 4.74 22.92.11 59.1c-.64 5.11 1.8 10.6 1.8 16.51 0 0-2.16 24.48 11.32 47.51s35.1 27.47 35.1 27.47zm105.09-105.7s4.86 11.29 4.86 21.3a77.32 77.32 0 0 1-5.89 28.52s-2.65-20.28-2.65-23.22 3.68-12.49 3.68-26.6zm-118.34 50C35.08 64.89 49.4 51 49.4 51c0 23.63 9.11 32.39 9.11 32.39A27.54 27.54 0 0 0 55 97a12.65 12.65 0 0 0 2.63 7.4S35.08 98 35.08 94.87zm55 66.08c-11.33 0-20.56-6.45-20.56-6.45s9.73 1.22 28.21-3 35.67-15.27 35.67-15.27-11.4 24.7-43.28 24.7zm-15.5-21.54c-13.45 0-20.07 0-25.51-5.3s-12.33-27.65-12.33-27.65 12.27 10.48 49 10.48c35.25 0 55.34-28.06 55.34-28.06s.09 2.55.09 18-42.47 32.51-66.55 32.51zM70.32 97a33.8 33.8 0 0 0 6.92 4 10.27 10.27 0 0 1-10.77-1c-5.31-4-3.17-9-3.17-9s4.17 3.77 7.02 6zm-2.25-37.61c0-4.69.18-13.41 8.68-13.41s23.84 10.29 23.84 25.46c0 9.49-1.3 16.46-6.67 16.46S68.07 74.66 68.07 59.39zM147.36 39c.33 4.53-1.68 17.53-1.68 17.53s-2.91-10-5-15.13-8.45-14.07-8.45-14.07c3.21-.04 15.13 4.99 15.13 11.67zM131.3 50s7.51 18.91 7.51 24.23-7 10.63-8.43 10.63c-.66 0 2.49-6.41 2.85-13.79.39-8.49-1.93-21.07-1.93-21.07zm-21.7 35.92a183.19 183.19 0 0 0 1.63-19c0-37.21-41.14-33.09-41.14-33.09a70 70 0 0 1 17.61-2.54c23.08 0 34 15.1 34 30.51-.05 13.05-12.1 24.12-12.1 24.12zM12.2 67.24c0-9.24 9.44-31.59 9.44-31.59s21.68-19.51 56.92-19.51 37 10.36 37 10.36a62.77 62.77 0 0 0-30.55-8c-40.75 0-60.27 46.34-60.27 69.81-.04 0-12.54-4.94-12.54-21.07zm1.68 26.9l11.45 8.6s2 7.91 4.08 13.86 5.38 13 5.38 13-5.07-2.24-12.46-13.64a54.44 54.44 0 0 1-8.45-21.83z"
            />
          </g>
        </svg>
      </div>

      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        elevation="2"
        rounded
        v-if="loggedIn"
        @click="logout"
      >
        ВИЙТИ
      </v-btn>
    </v-app-bar>

    <v-main style="background-color: #ddd">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import apiClient from '@/api/apiClient';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'App',
  created() {
    if (localStorage.getItem('app_dp_drw')) {
      this.setLoggedIn(true);
    }
    apiClient.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {

        switch (error.response.status) {
          case 400:
            console.log('Error status 400');
            this.logout();
            break;
          case 401:
            console.log('401 Session expired');
            this.logout();
            break;
          case 403:
            console.log('Error status 403');
            this.logout();
            break;
          case 404:
            console.log('Page not exists');
            // this.logout();
            break;
          case 500:
            console.log(error.response.data.error);
            this.logout();
            break;
          case 502:
            console.log('Page not exists');
            this.logout();
        }
        return Promise.reject(error);
      }
    );
  },
  computed: {
    ...mapState({
      loggedIn: state => state.loggedIn,
      statuses: state => state.statuses,
    })
  },
  methods: {
    ...mapActions({
      setLoggedIn: 'setLoggedIn',
    }),
    logout() {
      localStorage.removeItem('app_dp_drw');
      this.setLoggedIn(false);
      if (this.$router.currentRoute.path !== '/') {
        this.$router.push('/');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .logo-header {
    > svg {
      display: block;
      width: 36px;
      height: auto;
      fill: #ffffff;
    }
  }
</style>
