import axios from 'axios';

// const apiHost = 'https://lorashen.kitworks.systems/kw_api';
// const apiHost = 'https://md.donpion.ua/kw_api';
const apiHost = 'https://md3.donpion.ua/kw_api';
const baseURL = `${apiHost}`;

export default axios.create({
  baseURL,
  // headers: {
  //   Authorization: `Bearer ${localStorage.getItem('dp_t')}`,
  // },
});
