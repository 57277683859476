<template>
  <svg class="logo-main" :fill="color" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 422.5 422.5">
    <defs/>
    <path d="M422.5 215.4v-.2a9.7 9.7 0 00-9.7-9.5h-2.6l-39.4-76.3a9.7 9.7 0 00-8.7-5.2h-67.3l2.6-20.4a27.8 27.8 0 00-27.9-30.6h-209c-5.3 0-9.7 4-10.3 9.3l-2 16.8h119c7.8 0 13.9 6.2 13.7 14-.2 7.7-6.7 14-14.4 14H10.2c-5.5 0-10 4.4-10.2 10a9.6 9.6 0 009.6 9.8h154.5a14.5 14.5 0 0112.3 14.8c-.2 8.3-7 15-15.3 15H54.7c-5.6 0-10.2 4.5-10.3 10a9.7 9.7 0 009.7 10h105.2c7.3 1 12.8 7.3 12.6 14.9-.2 8.2-7.1 15-15.4 15H25.7c-5.6 0-10.2 4.4-10.3 10a9.7 9.7 0 009.7 10H34l-3.4 35.8a27.8 27.8 0 0027.9 30.6h7.5a43.6 43.6 0 0043.2 36.1 46.6 46.6 0 0045-36h94.3a32 32 0 0020.4-7.8c5.2 5 12 7.7 19.6 7.7h1.5a43.6 43.6 0 0043.2 36.1 46.6 46.6 0 0045-36h6.3a32.8 32.8 0 0032-29.4l6-67.5v-.3l.1-.4v-.3zm-312.9 114a24.3 24.3 0 01-24.3-25c.3-13.8 11.8-25 25.6-25a24.3 24.3 0 0124.4 25 25.8 25.8 0 01-25.7 25zm224 0a24.3 24.3 0 01-24.3-25c.3-13.8 11.8-25 25.6-25a24.3 24.3 0 0124.4 25 25.8 25.8 0 01-25.7 25zm62.9-46.8a12 12 0 01-11.6 10.6h-6.7a43.6 43.6 0 00-42.8-33.9c-21 0-39 14.5-44.5 34h-2c-2.6 0-4.8-1-6.4-2.8a8.6 8.6 0 01-2.1-6.6L293 144.2h29l-4.6 50.8a27.8 27.8 0 0027.9 30.6h56.3l-5.1 57z"/>
  </svg>
</template>

<script>
export default {
  name: "Logo",
  props: {
    color: {
      type: String,
      default: '#000000',
    }
  }
}
</script>

<style lang="scss" scoped>
  .logo-main {
    display: block;
    width: 40px;
    height: auto;
  }
</style>
